<template>
  <div class="jet-input jet-input-blob mt-3" 
       v-show="vis">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <img v-if="isImage" :src="`/static/model/view/${ value.id }`" contain height="600" @click="onview">
        <v-card v-else width="120" 
                height="80" 
                elevation="1">
          <v-hover v-if="hasValue" :disabled="dis" v-bind="attrs" v-on="on">
            <template v-slot:default="{ hover }">
              <v-card width="100%" height="100%" 
                      flat
                      v-on:click="onview">
                <v-btn fab
                       elevation="0"
                       :disabled="dis"
                       class="jet-btn-delete"
                       v-on:click.stop.prevent="onchange(null)">
                    <v-icon x-small>mdi-close</v-icon>
                </v-btn>
                <v-card-text>
                    <v-icon small v-show="hover">mdi-eye-outline</v-icon>&nbsp;
                    {{value.name}}
                </v-card-text>
                <v-card-actions>
                  <v-chip class="ma-2" label x-small :color="fileColor" text-color="white" >{{fileType}}</v-chip>
                </v-card-actions>
              </v-card>
            </template>
          </v-hover>
          <v-btn v-else 
                 class="empty-file" 
                 width="100%" 
                 height="100%" 
                 :loading="loading" 
                 :disabled="loading || dis" 
                 v-bind="attrs" 
                 v-on="on" 
                 v-on:click="oninput">
                <v-icon>mdi-cloud-upload-outline</v-icon>
          </v-btn>
        </v-card>
      </template>
      <span>{{value.name || label}}</span>
    </v-tooltip>
    <div hidden> 
      <input ref="control" type="file" @change="onchange">
      <a ref="view_control" :href="fileUrl" :download="value.name" target="_blank" />
      <v-text-field v-model="value.name" :value="value.name" :rules="rules"/>
    </div>
  </div>
</template>
<script>
import JetInputBase from '@/components/editors/JetInputBase';
import JetInputBtn from '@/components/editors/JetInputBtn';
import { isEmpty } from '@/utils';

const EMPTY_FILE = {
  id: null,
  name: null,
  mimeType: null,
  size: null,
  value: null
};

export default {
  name: 'JetInputBlob',
  extends: JetInputBase,
  components: {
    JetInputBtn
  },
  data() {
    return {
      loading: false,
      hover: false,
      value: EMPTY_FILE
    };
  },
  mounted() {
    if ( this.$attrs.value ) {
      var v = this.$attrs.value;
      this.value = Object.assign({}, {
        id: v.id,
        name: (v.attrs) ? v.attrs['Key{name=fileName, type=java.lang.String}'] : v.name,
        mimeType: (v.attrs) ? v.attrs['Key{name=mimeType, type=java.lang.String}'] : v.mimeType,
        size: (v.attrs) ? v.attrs['Key{name=fileSize, type=java.lang.Long}'] : v.size
      });
    } else {
      this.value = Object.assign({}, EMPTY_FILE);
    }
    console.log('mounted', this.value, this.$attrs.value);
  },
  computed: {
    isImage() {
      if ( !isEmpty(this.value.mimeType ) ) {
        return this.value.mimeType.indexOf('image') >= 0;
      }
      return false;
    },
    hasValue() {
      return !isEmpty(this.value.id);
    },
    fileType() {
      if ( this.value ) {
        var v = isEmpty(this.value.name) ? '. ' : this.value.name;
        return v.substr(v.indexOf('.') + 1);
      }
    },
    fileUrl() {
      if ( this.value ) {
        if ( this.value.id ) {
          return `/static/model/view/${ this.value.id }`;
        } else {
          var mimeType = this.value.mimeType;
          var blob = new Blob([this.value.value], {type: mimeType});
          return URL.createObjectURL(blob);
        }
      }
    },
    fileColor(){
        const _COLORS = {
            "#e63737": /^(pdf)+/i, 
            "#23975a": /^(xls)+/i, 
            "#004fc8": /^(doc)+/i, 
            "#004fc9": /^(odt)+/i
        };
        var color = "#141417";  //default
        Object.keys(_COLORS).map( c => {
            if (_COLORS[c].test(this.fileType)){
                color = c;
            }
        });
        return color;
    }
  },
  methods: {
    onchange(e) {
      if ( !( !!e ) ) {
        this.value = Object.assign({}, EMPTY_FILE);
        return;
      }
      
      
      const file = e.target?.files[0];

      if ( !(!!file) ) {
        this.value = Object.assign({}, EMPTY_FILE);
        return;
      }

      const self = this,
            reader = new FileReader();
    
      reader.onload = _e => {
            const data = _e.target.result;
            const mimeType = data.match(/data:(.+);/)[1];
            const commaIndex = data.indexOf(',');
            self.value = {
                id: null,
                name: file.name,
                mimeType: mimeType,
                size: file.size,
                value: data.substr(commaIndex + 1)
            };
            console.log('file loaded', self.value);
      };
      reader.readAsDataURL(file);
    },
    oninput() {
      const event = new MouseEvent('click');
      this.$refs.control.dispatchEvent(event);
    },
    onview() {
      const event = new MouseEvent('click');
      this.$refs.view_control.dispatchEvent(event);
    }
  },
  watch: {
    value(newValue, oldValue) {
        this.$attrs.value = newValue;
        this.$emit('input', newValue);
        this.$emit('datachange', this.name);
    }
  }
}
</script>
<style lang="scss" scoped>
  .v-btn__content .jet-input-blob {
    & .empty-file {
      white-space: normal;
    }
  }
  .v-card .jet-input-blob {
      & .jet-btn-delete{
          width: 28px;
          height: 28px;
          right: -14px;
          top: -14px;
          position: absolute;
      }
    & .v-card__actions{
      background-color: #f5f5f5;
      padding-bottom: 0px;
      padding-right: 0px;
      justify-content: flex-end;
      & .v-chip {
        font-weight: 600;
      }
    }
    & .v-card__text{
      background-color: #f5f5f5;
      font-size: .95em;
      font-weight: 800;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .v-overlay {
      justify-content: start;
      align-items: end;
  }
  
</style>