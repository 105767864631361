<template>
  <div class="jet-input jet-input-id" v-show="vis">
    <div hidden> 
      <v-text-field :value="value"></v-text-field>
    </div>
    <v-text-field v-model="text"
                  :label="label"
                  :placeholder="placeholder"
                  :value="text"
                  :loading="(mode === MODES.loading)"
                  :disabled="!!dis || (mode === MODES.loading)"
                  :rules="rules"
                  autocomplete="off"
                  @keydown.enter="_enter(text)"
                  @keydown.esc="_esc"
                  @keydown.down="_down">
      <template slot="append">
        <v-btn icon
               small
               @click="_down">
          <v-icon small>fas fa-chevron-down</v-icon>
        </v-btn>
      </template>
      <template v-if="(!noactions)" template v-slot:append-outer>
        <jet-input-btn @clear="rowSelect(null)"/>
      </template>
    </v-text-field>
    <v-menu v-model="attrs.on"
            tile
            :position-x="attrs.x"
            :position-y="attrs.y"
            :nudge-width="attrs.w"
            :max-height="attrs.h"
            content-class="jet-focused">
        <jet-table ref="cb_table" 
                 mode="single" 
                 :model="model" 
                 :search="search" 
                 :rows="items" 
                 :focused="!!attrs.on"
                 :loading="false"
                 v-on:cb_change="rowSelect($event)" />
    </v-menu>
  </div>
</template>
<script>
import { MODES, NULL_ID, isEmpty } from "@/utils";
import Model from '@/core/Model';
import JetInputBase from '@/components/editors/JetInputBase';
import JetInputBtn from '@/components/editors/JetInputBtn';
import JetTable from '../JetTable.vue';
import { _stop_e } from '../JetTable.vue';

const EMPTY_MODEL = new Model( {
            id: NULL_ID,
            viewId: NULL_ID,
            idColumnId: "id",
            columns: [],
            projects: []
    }
);


export default {
  name: 'JetInputId',
  extends: JetInputBase,
  components: {
    JetInputBtn,
    JetTable
  },
  data() {
    return {
        MODES,
        model: EMPTY_MODEL,
        mode: MODES.none,
        items: [],
        selected: {},
        value: this.$attrs.value,
        text: null,
        search: '',
        isLoad: false,
        filter: null,
        url: {
            uri: null,
            props: null
        },
        attrs: {
            on: false,
            w: 0,
            h: 0,
            x: 0,
            y: 0
        }
    };
  },
  mounted(){
      if ( !isEmpty(this.value) ){
        this.$nextTick(this.refresh);
      } 
  },
  computed: {
    hasEditor() {
      return this.editorUri !== null;
    },
    loaded() {
      if ( this.isLoad ) {
          return true;
      } else if ( !isEmpty(this.uri) ) {
          this.refresh();
      } 
      return false;
    }
  },
  methods: {
    has(q){
        switch(q){
            default:
                return false;
        }
    },
    setFilter(filter) {
      console.log('set a filter', filter, this);
      this.filter = filter;
      (async ()=>{
            this.items = []; //for reloading
            this.rowSelect(null);
            await this.refresh();
            if ((this.required)&&(!!this.items)&&(this.items.length===1)){
                this.rowSelect(this.items[0]);
            }
      })();
    },
    Values(name){
      return this.values(name);
    },
    values(name){
      return (this.selected) ? this.selected[name] : null;
    },
    getItems() {
      return this.items;
    },
    getUri: function() {
      try {
        var res = this.url.uri;
        var props = (this.url.props.length > 0) ? this.url.props : [];
        if ( this.filter ) {
          var filter = (props.length > 0) ? props.filter((p)=>{return (p.key==='filter');}) : null;
          if (filter !== null) {
            filter = ( filter.length > 0 ) ? 'and(' + filter[0].val + ',' + this.filter + ')' : this.filter;
          } else {
            filter = this.filter;
          }
          props.push({key: 'filter', val: filter});
        }
        if ( props.length > 0 ) {
          res = res + '?1=1';
          props.map((p)=>{
              res = res + '&' + p.key + '=' + p.val;
          });
        }
        return res;
      } catch (err) {
        return null;
      }
    },
    async refresh(){
        if (
                (!!this.model)
             && ((this.items?.length || 0) > 0)
            ) {
            return;
        }
        if ( isEmpty(this.uri) ){
            return;
        }
        this.mode = MODES.loading;
        var clone = this.url;
        var u = ( this.uri.indexOf("?") > 0 ) ? this.uri.substr(0, this.uri.indexOf("?")) : this.uri;
        var p = ( this.uri.indexOf("?") > 0 ) ? this.uri.substr(this.uri.indexOf("?") + 1, 1024) : "";
        var uriPrms = new URLSearchParams(p); 
        var params = [];
        for(var key of uriPrms.keys()) { 
          params.push({key: key, val: uriPrms.get(key)});
        }
        clone.uri = u;
        clone.props = params;
        try {
            var data = await $http.post('/rpc?d=jsonRpc', {
                    type: 'core-read',
                    query: this.getUri()
                });
            if (!!data.error){
                throw data.error;
            }
            this.model = new Model(data.result.model);
            //this.items = model.sin2obj(data.result.data);
            var ci = this.model.columnIndexes;
            var r = [];
            data.result.data.map((_raw, i)=>{
              var row = {};
              for (const columnId in ci) {
                if (ci.hasOwnProperty(columnId)) {
                  const index = ci[columnId];
                  row[columnId] = _raw[index];
                }
              }
              r.push(row);
            });
            this.items = r;
            this.isLoad = true;
            this.text = null;
            this.value = this.$attrs.value;
            if ( !!this.value ) {
              var self = this;
              this.items.map((item)=>{
                if ( item[this.model.columnId] === self.value ) {
                  self.rowSelect(item);
                  return;
                }
              });
            }
            this.mode = MODES.default;
            
        } catch(err) {
            console.log(err);
            this.mode = MODES.error;
            jet.msg({text:'ВНИМАНИЕ! Ошибка получения данных<br /><small>Информация для технической поддержки: '
                      + err.message + '</small>', color:'warning'});
        }

    },
    rowSelect: function(data) {
      this.selected = data;
      this.value = (data) ? data[this.model.columnId] : null;
      this.text = (data) ? this.model.getObjName(data) : null;
      this.$emit('input', this.value);
      this.$emit('datachange', this.name);
    },
    _enter: function(value) {
        const refr = !(this.items?.length > 0);
        this.search = isEmpty(value) ? '' : value;
        if (refr) {
            (async ()=>{
                await this.refresh();
                this.attrs.on = (new Date()).getTime();
            })();
        } else {
            this.attrs.on = (new Date()).getTime();
        }
    },
    _esc: function() {
        this.attrs.on = false;
    },
    _down: function(e){
        if ( this.attrs.on ){
            _stop_e(e);
            this.$refs["cb_table"].focus();
            return false;
        }
        this._enter(this.search);
    }
  },
  watch: {
      /**
       * show / hide menu handle
       */
      "attrs.on"(val){  
            console.log("attrs.on", val);
            if (!!val){
                var bc = $(this.$el).get(0).getBoundingClientRect();
                this.attrs.h = 360;
                this.attrs.w = bc.width;
                this.attrs.x = bc.left;
                this.attrs.y = bc.top + bc.height;
                this.mode = MODES.freeze;
                $(this.$el).find("input").blur();
                setTimeout(()=>{
                    this.$refs["cb_table"].focus();
                    this.$nextTick(()=>{
                        this.mode = MODES.default;
                    });
                }, 300);
            } else {
                $(this.$el).find("input").focus();
            }
      }
  }
};
</script>
