<template>
    <v-dialog v-model="show" width="600">
        <v-card>
            <v-card-title>Выберите тип реестра</v-card-title>
            <v-divider />
            <v-card-subtitle class="mt-4 mb-4">
                <v-radio-group v-model="target">
                    <v-radio label="Реестр межмуниципальных маршрутов" value="mm" />
                    <v-radio label="Реестр межрегиональных маршрутов" value="mr" />
                </v-radio-group>
            </v-card-subtitle>
            <v-card-text>
                <div v-if="result">
                    Реестр сформирован
                    <v-btn :href="`/api/busreports/reestr?target=` + target + `&mode=4`" color="primary" dark>Просмотр</v-btn>
                </div>
                <div v-else>
                    Выберите тип реестра и нажмите "Сформировать"
                </div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn @click="generate" :loading="loading" color="primary" dark>Сформировать</v-btn>
                <v-btn @click="show = false" color="gray" outlined>Закрыть</v-btn>
            </v-card-actions>  
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'Reestr',
    data: () => ({
        show: true,
        target: true,
        loading: false,
        result: false
    }),
    methods: {
        async generate() {
            this.loading = true;
            
            const uri = '/busreports/reestr?target=' + this.target + '&mode=1';
            const result = await jet.http.get(uri);
            this.result = result.success;

            this.loading = false;
        }
    }
}
</script>